@import "../variables";
@import "../mixins";

.ce-images-slider {
    &__wrapper {
        position: relative;
        width: 100%;
        height: 500px;
        overflow: hidden;
        overflow-x: auto;

        @include mediaQuery($device_smartphone) {
            height: 320px;
        }
    }

    &__items {
        display: table;
        height: inherit;
    }

    &__item {
        display: table-cell;
        height: inherit;
        position: relative;

        picture {
            margin-left: 40px;
            height: 81%;
            display: block;
            width: auto;
            max-width: unset;

            @include mediaQuery($device_smartphone) {
                margin-left: 20px;
            }
        }

        img {
            display: block;
            width: auto;
            max-width: unset;
            height: inherit;
        }

        &:first-child {
            picture {
                margin-left: 0;
            }
        }
    }

    .slider-btn {
        display: inline-block;
        color: $color-highlight;
        width: 23px;
        
        &--right {
            margin-left: 50px;
        }
    }

    &__buttons {
        text-align: right;
    }

    .history-text {
        position: absolute;
        display: inline-block;
        background-color: white;
        left: 40px;
        right: 0;
        top: 65%;
        height: 35%;
        padding: 8px 16px;

        @include mediaQuery($device_smartphone) {
            left: 20px;
            font-size: 13px;
        }

        &-year {
            margin-top: 6px;
            display: block;
            color: $color-highlight;
            text-align: center;
            margin-bottom: 10px;
        }
    }

    @include mediaQuery($device_smartphone) {
        .history-text {
            font-size: 13px;
            left: 20px;
            top: 57%;
            height: 43%;
        }
    }

    .ce-images-slider__items {
        .ce-images-slider__item:first-child {
            .history-text {
                left: 0;
            }
        }
    }
}
