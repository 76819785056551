#bgslider-image-wrap-right,
#bgslider-image-wrap-right-2 {
  position: absolute;
  width: 50vw;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: grey;
}

#bgslider-image-wrap-left,
#bgslider-image-wrap-left-2 {
  position: absolute;
  width: 50vw;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-color: grey;
}

.bgslider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform ease-in-out 1s; // better to animate transform and not left b/c it's easier for the browser
  transform: translateX(100%); // all images which are not currently animated or visible
  z-index: 0;
  background-size: cover;
  
  // used to move the current visible image to the left out of the view
  &.previously-active {
    transform: translateX(-100%); 
    z-index: 1;
  }
  
  // used to move the next image into the view
  &.active {
    transform: translateX(0); 
    z-index: 2;
  }
}