.tx-mab-pages-product {
    // mansory grid system
    .product-grid {
        margin-top: -1rem;
        margin-left: -1rem;

        .product {
            a {
                display: block;
                margin-top: 1rem;
                margin-left: 1rem;
            }
        }

        @media only screen and (max-width: 768px) {
            .one-half {
                width: 100%;
            }

            .one-third {
                width: 50%;
            }
        }

        @media only screen and (max-width: 480px) {
            .one-third {
                width: 100%;
            }
        }
    }

    .grid-sizer {
        width: 8.3333333333%;
    }




    figcaption {
        margin: 0 !important;
    }

    //$height: 15rem;
    //.imagerow {
    //	position: relative;
    //
    //	.slide-container {
    //		overflow: hidden;
    //		position: relative;
    //		height: $height;
    //	}
    //
    //	.slide {
    //		position: absolute;
    //		top: 0;
    //		left: 0;
    //		height: $height;
    //		cursor: move;
    //		white-space: nowrap;
    //	}
    //
    //	.slide-element {
    //		display: inline-block;
    //		height: $height;
    //		margin-left: 1rem;
    //
    //		&:first-child {
    //			margin-left: 0;
    //		}
    //
    //		a,
    //		.image {
    //			height: $height;
    //			position: relative;
    //			white-space: normal;
    //		}
    //
    //		img {
    //			width: auto;
    //			max-width: none;
    //			height: $height;
    //		}
    //	}
    //
    //	$button-size: 5rem;
    //	$icon-size: 1.7rem;
    //	.buttons {
    //		position: relative;
    //		height: 0;
    //	}
    //
    //	.button-left,
    //	.button-right {
    //		position: absolute;
    //		width: $button-size;
    //		height: $button-size;
    //		top: $height / -2;
    //		margin-top: $button-size / -2;
    //		background-color: rgba(0,0,0,1);
    //		cursor: pointer;
    //		border-radius: $button-size / 2;
    //
    //		&:after {
    //			content: "";
    //			font-family: FontAwesome;
    //			font-style: normal;
    //			font-weight: normal;
    //			position: absolute;
    //			top: 50%;
    //			margin-top: $icon-size / -2;
    //			width: $icon-size;
    //			height: $icon-size;
    //			font-size: $icon-size;
    //			line-height: $icon-size;
    //			color: white;
    //			text-align: center;
    //		}
    //
    //		html.no-touch &:hover {
    //			background-color: rgba(0,0,0,.8);
    //		}
    //	}
    //
    //	.button-left {
    //		left: 2rem;
    //
    //		&:after {
    //			content: "\f053";
    //			left: 50%;
    //			margin-left: $icon-size / -1.9;
    //		}
    //	}
    //
    //	.button-right {
    //		right: 2rem;
    //
    //		&:after {
    //			content: "\f054";
    //			right: 50%;
    //			margin-right: $icon-size / -1.9;
    //		}
    //	}
    //}

    // The product- or project items image
    .image {
        position: relative;
        $space: 0;

        html.touch & {
            .image-caption {
                top: auto;
                opacity: 1;
                -webkit-transition: opacity inherit;
                -moz-transition: opacity inherit;
                -ms-transition: opacity inherit;
                transition: opacity inherit;
            }
        }

        .image-caption {
            position: absolute;
            top: $space;
            left: $space;
            right: $space;
            bottom: $space;
            background-color: rgba(0, 0, 0, 0.6);
            overflow: hidden;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }

        .image-caption-table {
            display: table;
            width: 100%;
            height: 100%;
        }

        .image-caption-table-cell {
            display: table-cell;
            width: 100%;
            height: 100%;
            text-align: center;
            vertical-align: middle;
        }

        .image-caption-content {
            margin: $space;
            padding: 1em;
            position: relative;
            //font-family: $font-family-header;
            //font-weight: $font-weight-header-regular;
            font-size: 18px;
            line-height: 1.2;
            text-transform: uppercase;
            letter-spacing: 0.075em;
            font-weight: 400;
            color: $color-highlight;

            @media only screen and (max-width: 480px){
                font-size: 16px;
            }
            //&:before {
            //	content: " ";
            //	position: absolute;
            //	top: -.5rem;
            //	left: 50%;
            //	margin-left: -1.25rem;
            //	width: 2.5rem;
            //	height: 2px;
            //	background-color: white;
            //}
        }

        //.image-title {
        //	font-size: getRemFromPt(30);
        //	line-height: 1.2;
        //	text-transform: uppercase;
        //	letter-spacing: $letter-spacing;
        //}

        //.image-description {
        //	font-size: getRemFromPt(13.5);
        //	line-height: 1.4;
        //	margin-top: 1rem;
        //}

        //@include smartphoneMediaQuery {
        //	.image-title {
        //		font-size: getRemFromPt(17);
        //		line-height: 1.3;
        //	}
        //}

        html.no-touch &:hover {
            .image-caption {
                opacity: 1;
            }
        }

        html.touch & {
            .image-caption.active {
                opacity: 1;
            }
        }
    }

    // logos in detail view
    //.product-page .logos {
    //	.logo {
    //		position: relative;
    //		display: block;
    //		width: 100%;
    //		height: 0;
    //		padding-bottom: 100%;
    //		overflow: hidden;
    //		border: 1px solid white;
    //		background-color: transparent;
    //		transition: background-color ease-in-out .5s;
    //
    //
    //		img {
    //			position: absolute;
    //			top: 5%;
    //			left: 5%;
    //			width: 90%;
    //			height: 90%;
    //			max-width: none;
    //			z-index: 0;
    //		}
    //
    //		img.image-active {
    //			opacity: 0;
    //			transition: opacity ease-in-out .5s;
    //			z-index: 0;
    //		}
    //
    //		html.no-touch &:hover {
    //			background-color: white;
    //			img.image-active {
    //				opacity: 1;
    //			}
    //		}
    //	}
    //}
}

// Navigation in Lightbox
// = = = = = = = = = = = = = = = = = = = = = = = =

.tx-mab-pages-product .product-page {
    nav {

        ul {
            position: relative;
        }

        li {
            display: inline-block;
            min-width: 75px;

            //&.previous {
            //
            //}

            &.next {
                margin-left: 10px;
                position: absolute;
                right: 0;
            }
        }

        a {
            display: block;
            color: white;
            text-decoration: none;
            padding: 0.3em 0.75em;
            font-weight: $font-weight-light;
            text-transform: uppercase;
            font-size: 100%;
            letter-spacing: $letter-spacing;
            line-height: 1.3;
            transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;

            #{$no-touch-selector} &:hover {
                text-decoration: none;
                color: $color-highlight;
            }
        }

        //@include mediaQuery($device_smaller_than_laptop) {
        //    ul {
        //        align-items: start;
        //        flex-wrap: wrap;
        //    }
        //
        //    li {
        //        &.previous {
        //            min-width: 0;
        //            order: 1;
        //        }
        //
        //        &.title {
        //            order: 0;
        //            width: 100%;
        //        }
        //
        //        &.next {
        //            min-width: 0;
        //            order: 2;
        //        }
        //    }
        //}
    }
}

.reference-title {
    text-align: center;
    margin-top: 30px;

    @media only screen and (max-width: 480px) {
        margin-bottom: 20px;
    }

    h1 {
        font-size: 2.5em;
        line-height: 1.2;
        text-transform: uppercase;
        padding-bottom: 0;
        font-weight: $font-weight-light;
        font-family: $font-family;
        letter-spacing: initial;

        @media only screen and (max-width: 480px) {
            font-size: 1.5em;
        }
    }
}

.ce-generic-swipe {
    html.touch & {
        .jsc-button-left {
            display: none !important;
        }
        .jsc-button-right {
            display: none !important;
        }
    }
}






$height: 15rem;
.mabcontentelements-imagerow {
    position: relative;

    html.touch & {
        .image-caption {
            top: auto;
            opacity: 1;
            -webkit-transition: opacity inherit;
            -moz-transition: opacity inherit;
            -ms-transition: opacity inherit;
            transition: opacity inherit;
        }
    }

    .slide-container {
        overflow: hidden;
        position: relative;
        height: $height;
    }

    .slide {
        position: absolute;
        top: 0;
        left: 0;
        height: $height;
        cursor: move;
        white-space: nowrap;
    }

    .slide-element {
        display: inline-block;
        height: $height;
        margin-left: 1rem;

        &:first-child {
            margin-left: 0;
        }

        a,
        .image {
            height: $height;
            position: relative;
            white-space: normal;
        }

        img {
            width: auto;
            max-width: none;
            height: $height;
        }
    }

    .slide-element {
        $space: 0;

        .image-caption {
            position: absolute;
            top: $space;
            left: $space;
            right: $space;
            bottom: $space;
            background-color: rgba(0, 0, 0, 0.8);
            overflow: hidden;
            opacity: 0;
            -webkit-transition: opacity 0.5s ease-in-out;
            -moz-transition: opacity 0.5s ease-in-out;
            -ms-transition: opacity 0.5s ease-in-out;
            transition: opacity 0.5s ease-in-out;
        }

        .image-caption-table {
            display: table;
            width: 100%;
            height: 100%;
        }

        .image-caption-table-cell {
            display: table-cell;
            width: 100%;
            height: 100%;
            text-align: center;
            vertical-align: middle;
        }

        .image-caption-content {
            margin: $space;
            padding: 1rem 0;
            position: relative;
            //font-family: #{$font-family-header};
            //font-weight: $font-weight-header-regular;
            color: $color-highlight;

            &:before {
                content: " ";
                position: absolute;
                top: -0.5rem;
                left: 50%;
                margin-left: -1.25rem;
                width: 2.5rem;
                height: 2px;
                background-color: white;
            }
        }

        .image-title {
            font-size: getRemFromPt(30);
            line-height: 1.2;
            text-transform: uppercase;
            letter-spacing: $letter-spacing;
            color: $color-highlight;
        }

        .image-description {
            font-size: getRemFromPt(13.5);
            line-height: 1.4;
            margin-top: 1rem;
        }

        html.no-touch & {
            .image:hover .image-caption {
                opacity: 1;
            }
        }

        html.touch & {
            .image-caption.active {
                opacity: 1;
            }
        }
    }

    $button-size: 5rem;
    $icon-size: 1.7rem;
    .buttons {
        position: relative;
        height: 0;
    }

    .button-left,
    .button-right {
        position: absolute;
        width: $button-size;
        height: $button-size;
        top: $height / -2;
        margin-top: $button-size / -2;
        background-color: rgba(0, 0, 0, 1);
        cursor: pointer;
        border-radius: $button-size / 2;

        &:after {
            content: "";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            position: absolute;
            top: 50%;
            margin-top: $icon-size / -2;
            width: $icon-size;
            height: $icon-size;
            font-size: $icon-size;
            line-height: $icon-size;
            color: white;
            text-align: center;
        }

        html.no-touch &:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

    .button-left {
        left: 2rem;

        &:after {
            content: "\f053";
            left: 50%;
            margin-left: $icon-size / -1.9;
        }
    }

    .button-right {
        right: 2rem;

        &:after {
            content: "\f054";
            right: 50%;
            margin-right: $icon-size / -1.9;
        }
    }
}

.ce-generic-swipe {
    html.touch & {
        .jsc-button-left {
            display: none !important;
        }
        .jsc-button-right {
            display: none !important;
        }
    }
}






$grid-space: 2rem;

@import "../../vendor/sunixzs/JSCollection/src/variables";

// Override button styles
// outer button
$button-width: 3rem;
$button-height: 3rem;
$button-background-color: rgba(black, 0.5);
$button-background-color-hover: rgba($color-highlight, 0.8);

// inner button
$button-icon-width: 2rem;
$button-icon-height: 2rem;

// SVG icon in inner button
$button-icon-svg-height: 70%;
$button-icon-svg-color: white;
$button-icon-svg-color-hover: black;

// generic swipe
$generic-swipe-grid-space: 0.25em;

@import "../../vendor/sunixzs/JSCollection/src/ContentElement/GenericSwipe";
@import "../../vendor/sunixzs/JSCollection/src/Dom/ButtonLeftRight";

// equal height
.ce-generic-swipe-equal-height-images {
    .contentelement {
        height: 400px;
        padding-left: $grid-space / 2;
        &:first-child {
            padding-left: 0;
        }

        a,
        figure,
        img {
            display: block;
            height: inherit;
            width: auto;
            max-width: 10000px;
        }
    }

    @media screen and (max-width: 480px),
           screen and (max-height: 480px) {
        .contentelement {
            height: 320px;
        }
    }
}

// space
.ce-generic-swipe {
    &.ce-generic-swipe-space {
        .contentelements {
            .contentelement {
                & > * {
                    display: block;
                    margin-left: $generic-swipe-grid-space;
                    margin-right: $generic-swipe-grid-space;
                }
            }
        }
    }
}










// Lightbox
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

$lightbox-z-index: 2000;

// selectors
$lightbox-active: "lightbox-active";
$lightbox-loading: "lightbox-loading";
$lightbox-background: "lightbox-background";
$lightbox-close: "lightbox-close";
$lightbox-content-container: "lightbox-content-container";
$lightbox-multiple-items: "multiple-items";
$lightbox-multiple-items-container: "lightbox-multiple-items-container";
$lightbox-content: "lightbox-content";
$lightbox-content-iframe: "lightbox-content-iframe";
$lightbox-content-ajax: "lightbox-content-ajax";
$lightbox-content-image: "lightbox-content-image";
$lightbox-html-no-touch: "html.no-touch";
$lightbox-previous: "lightbox-previous";
$lightbox-next: "lightbox-next";
$lightbox-info: "lightbox-info";
$lightbox-opacity-animation: "lightbox-opacity-animation";

// close-button
$lightbox-close-size: 3rem;
$lightbox-close-size-below-minsize: 2rem;
$lightbox-close-angle: 135deg;

// left-/right-button
$lightbox-button-width: 60px;
$lightbox-arrow-width: 2rem;
$lightbox-arrow-height: 2px;

// spinner/loading-animation
$lightbox-spinner-size: 3rem;

// colors
$lightbox-color-inactive: white;
$lightbox-color-active: $color-highlight;
$lightbox-background-color: mix($color-highlight, white, 70%);
$lightbox-spinner-color: white;

// HTML-Element active state
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
html.#{$lightbox-active} {
    overflow: hidden;

    body {
        overflow: hidden;
    }
}

.#{$lightbox-opacity-animation} {
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

// Spinner/Loading
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-loading} {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $lightbox-spinner-size;
    height: $lightbox-spinner-size;
    margin-left: $lightbox-spinner-size / -2;
    margin-top: $lightbox-spinner-size / -2;
    text-align: center;

    i {
        font-size: $lightbox-spinner-size;
        line-height: $lightbox-spinner-size;
        color: $lightbox-spinner-color;
    }

    //.lightbox-loading-icon {
    //	position: absolute;
    //	top: 50%;
    //	left: 50%;
    //	width: $lightbox-spinner-size;
    //	height: $lightbox-spinner-size;
    //	margin-left: $lightbox-spinner-size / -2;
    //	margin-top: $lightbox-spinner-size / -2;
    //	background: {
    //		image: url("../../Images/Icons/Preloader_highlight.svg");
    //		position: 50% 50%;
    //		size: $lightbox-spinner-size $lightbox-spinner-size;
    //		repeat: no-repeat;
    //	}
    //
    //	-webkit-animation: spin 4s linear infinite;
    //	   -moz-animation: spin 4s linear infinite;
    //	        animation: spin 4s linear infinite;
    //}
}

//@-moz-keyframes spin {
//	100% {
//		-moz-transform: rotate(360deg);
//	}
//}
//
//@-webkit-keyframes spin {
//	100% {
//		-webkit-transform: rotate(360deg);
//	}
//}
//
//@keyframes spin {
//	100% {
//		-webkit-transform: rotate(360deg); transform:rotate(360deg);
//	}
//}

// Background
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-background} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $lightbox-background-color;
    //background-color: rgba(red($lightbox-background-color), green($lightbox-background-color), blue($lightbox-background-color), 0.8);
    z-index: $lightbox-z-index;
}

// Info-Area
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-info} {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: $lightbox-z-index + 2;
    color: $lightbox-color-inactive;
    font-size: 13px;

    &.#{$lightbox-multiple-items} {
        right: 60px;
    }
}

// The Container including all contents (outer wrap)
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-content-container} {
    position: fixed;
    top: 23px;
    left: 0;
    right: 0;
    bottom: 60px;
    overflow: hidden;
    z-index: $lightbox-z-index + 1;

    &.#{$lightbox-multiple-items} {
        top: 60px;
        left: 60px;
        right: 60px;
        bottom: 60px;
    }
}

// The container holding multiple content-items (if there are multiple items)
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-multiple-items-container} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200%;
    cursor: move;
}

// A content-element.
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-content} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    overflow: hidden;
    color: $lightbox-color-inactive;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }

    // A content-element in ajax-mode
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
    &.#{$lightbox-content-ajax} {
        overflow: auto;
    }

    // A content-element in image-mode
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
    &.#{$lightbox-content-image} {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
    }

    // When we have an iOS touch-device
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
    &.ios {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch !important;
    }
}

// A content-element when we have multiple items
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-multiple-items-container} .#{$lightbox-content} {
    position: relative;
    float: left;
    top: auto;
    left: auto;
    width: 50%;
    height: 100%;
}

// Close-Button
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-close} {
    position: fixed;
    right: $lightbox-close-size * 0.75;
    top: $lightbox-close-size * 0.75;
    width: $lightbox-close-size;
    height: $lightbox-close-size;
    margin-top: $lightbox-close-size / -2;
    margin-right: $lightbox-close-size / -2;
    cursor: pointer;
    z-index: $lightbox-z-index + 3;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;

    &:before,
    &:after {
        position: absolute;
        content: "";
        display: inline-block;
        width: $lightbox-close-size;
        height: 2px;
        top: -1px;
        left: 0;
        background-color: $lightbox-color-inactive;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    &:before {
        -webkit-transform: translateY($lightbox-close-size / 2) rotateZ(-45deg);
        -moz-transform: translateY($lightbox-close-size / 2) rotateZ(-45deg);
        -ms-transform: translateY($lightbox-close-size / 2) rotateZ(-45deg);
        -o-transform: translateY($lightbox-close-size / 2) rotateZ(-45deg);
        transform: translateY($lightbox-close-size / 2) rotateZ(-45deg);
    }

    &:after {
        -webkit-transform: translateY($lightbox-close-size / 2) rotateZ(45deg);
        -moz-transform: translateY($lightbox-close-size / 2) rotateZ(45deg);
        -ms-transform: translateY($lightbox-close-size / 2) rotateZ(45deg);
        -o-transform: translateY($lightbox-close-size / 2) rotateZ(45deg);
        transform: translateY($lightbox-close-size / 2) rotateZ(45deg);
    }

    #{$lightbox-html-no-touch} &:hover {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);

        &:before,
        &:after {
            background-color: $lightbox-color-active;
        }
    }
}

// Previous- and Next-Buttons
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.#{$lightbox-previous},
.#{$lightbox-next} {
    position: fixed;
    width: $lightbox-button-width;
    top: 0;
    bottom: 0;
    //background-color: rgba(255,0,0,.5);
    cursor: pointer;
    z-index: $lightbox-z-index + 2;

    &:before,
    &:after {
        content: " ";
        position: absolute;
        width: $lightbox-arrow-width;
        height: $lightbox-arrow-height;
        background-color: $lightbox-color-inactive;
        top: 50%;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }

    html.no-touch &:hover {
        &:before,
        &:after {
            background-color: $lightbox-color-active;
        }
    }
}

.#{$lightbox-previous} {
    left: $lightbox-button-width / 10;

    &:before {
        left: $lightbox-button-width / 10;
        margin-top: $lightbox-arrow-width / -3;
    }

    &:after {
        left: $lightbox-button-width / 10;
        margin-top: $lightbox-arrow-width / 3;
    }
}

.#{$lightbox-next} {
    right: $lightbox-button-width / 10;

    &:before {
        right: $lightbox-button-width / 10;
        margin-top: $lightbox-arrow-width / 3;
    }

    &:after {
        right: $lightbox-button-width / 10;
        margin-top: $lightbox-arrow-width / -3;
    }
}



.button-lightbox-next{

    letter-spacing: $letter-spacing;

    &:after {
        content: url(/typo3conf/ext/app/Resources/Public/gfx/icons/button-right.svg);
        display: inline-block;
        background-color: $color-highlight;
        width: 0.5rem; 
        padding: 0px 7px;
        margin-left: 10px;
    }
}

.button-lightbox-previous{

    letter-spacing: $letter-spacing;

    &:before {
        content: url(/typo3conf/ext/app/Resources/Public/gfx/icons/button-right.svg);
        display: inline-block;
        background-color: $color-highlight;
        width: 0.5rem; 
        padding: 0px 7px;
        margin-right: 10px;
        transform: rotate(180deg);
    }
}
