$actSelector: "html.header-overlay-active";
$inactSelector: "html.header-overlay-inactive";

@keyframes header-overlay-active {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes header-overlay-inactive {
    from {
        top: 0;
        opacity: 1;
    }

    99% {
        top: 0;
    }

    to {
        opacity: 0;
        top: -100%;
    }
}



.openingtimes {
    display: block;
    background-color: $color-highlight;

    .page-centered {
        position: relative;
        height: 40px;

        .times {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            color: white;
            padding: 0;
        }
    }
}


#header {
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    position: relative;

    &__logo {
        width: 470px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    &__logo_dark {
        display: none;
    }

    &__image {
        position: relative;

        @include mediaQuery($device-smartphone) {
            margin-top: 50px;
        }
    }

    &__toptext {
        text-align: center;
        padding: 48px 16px;

        @include mediaQuery($device_smartphone) {
            display: none;
        }

        .font-header-regular-big {
            @include mediaQuery($device_smartphone) {
                font-size: 16px;
            }
        }
    }

    
}


#language-selector {

    position: absolute;
    top: 50%;
    transform: translateY(-55%);

    ul {
        li {
            display: inline-block;
            margin-right: 10px;
            width: 35px;
            opacity: 0.5;
            transition: opacity 0.3s ease-in-out;

            &.active {
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }
            
            a {
                img {
                    box-shadow: 2px 3px 5px rgba(0,0,0,0.3);
                }
            }
        }
    }
}

@media screen and (max-width: 2060px) {

    .openingtimes {
        padding: 0 2rem;
    } 
 
    #header {


        &__social {
            right: 2rem;
        }
    }
} 

@media screen and (max-width: 1500px) {
    #{$actSelector} {
        #c268 { 
            li {
                margin-top: 20px;
                font-size: 50px;
            }
        }
    }
}

@include mediaQuery($device_smaller_than_desktop) {

    #header {

        &__logo {
            width: 300px;
        }

        &__toggle {
            margin-left: 32px;
        }
    }
}


@include mediaQuery($device_laptop) {
    #{$actSelector} {
        #c268 {
            left: 2rem;
        }
    }

    #header {

        &__toggle {
            top: -30px;
            right: 1.8rem;
        }

        &__overlay {
            background-size: 60vw;
        }
    }
}

@include mediaQuery($device_smartphone) {

    #header {
        &__logo {
            display: none;

            &_dark {
                display: block;
                width: 75%;
                margin: 40px auto 90px;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .openingtimes {
        padding: 0;

        .times {
            font-size: 3.7vw;
        }
    }
}

@media screen and (max-width: 330px) {
    .openingtimes {
        padding: 0;
    }
}