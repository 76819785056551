// Lists
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

ul,
ol {
    padding: 0 0 0 1.25em;
    margin: 0 0 0 0;
    @include standard-padding-bottom(1.5);

    li {
        position: relative;
        padding: 0;
        margin: 0;
    }

    ul,
    ol,
    &:last-child {
        padding-bottom: 0;
    }
}

.standard-padding-bottom {
    @include standard-padding-bottom();
}

.ce-textmedia ul {
    //padding-left: .2em;

    li {
        //list-style: none;
        position: relative;
        //padding-left: 1.4em;

        //&:before {
        //	position: absolute;
        //	content: " ";
        //	top: .4em;
        //	left: 0;
        //	width: .8em;
        //	height: .8em;
        //	background-image: url("../Images/Icons/ListItem.svg");
        //	background-size: .8em .8em;
        //	background-repeat: no-repeat;
        //	background-position: 50% 50%;
        //}
    }
}

// Links
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

//.rte a {
//    text-decoration: none;
//    //color: black;
//
//    html.no-touch &:hover {
//        text-decoration: none;
//    }
//}

a.envelope,
a.phone,
a.fax {
    text-decoration: none;
    padding-bottom: auto;
    border-bottom: none;

    i {
        width: 1.75em;
        color: $color-highlight;
    }

    html.no-touch &:hover {
        text-decoration: none;
        //color: black;
    }
}

a {
    @include transition(color 0.3s ease);
}

a.iframe-no-decoration,
a.no-decoration {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

a.colored-button,
a.colored-button-iframe,
a.white-button,
a.white-button-iframe {
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    padding: 0.1em 0.4em;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: $letter-spacing;

    transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;

    #{$no-touch-selector} &:hover {
        text-decoration: none;
    }

    &:after {
        content: "▸";
        margin-left: 0.5em;
    }
}

a.colored-button,
a.colored-button-iframe {
    border-color: $color-highlight;
    background-color: $color-highlight;
    color: white;

    #{$no-touch-selector} &:hover {
        background-color: white;
        color: $color-highlight;
    }
}

a.white-button,
a.white-button-iframe {
    border-color: white;
    background-color: white;
    color: $color-highlight;

    #{$no-touch-selector} &:hover {
        background-color: $color-highlight;
        color: white;
    }
}

// Paragraphs
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

h1,
h2,
h3,
h4,
h5 {
    @include standard-padding-bottom(1.5);
}

pre,
p,
blockquote {
    @include standard-padding-bottom(1);

    &:last-child {
        padding-bottom: 0;
    }
}

.text-columne-count-2,
.text-columne-count-3 {
    p {
        padding-bottom: 1.5em !important;
    }
}

hr {
    border: none;
    height: 1px;
    background-color: black;
    margin-bottom: getRemFromPx(14);

    .color-white & {
        background-color: white;
    }
}

// Captions
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

//figcaption,
//.csc-textpic-caption {
//font-size: smaller;
//padding-top: .5em;
//line-height: 130%;
//}

figcaption p {
    margin-bottom: 0;
}

// sub and super
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

sub {
    vertical-align: sub;
    line-height: 0;
    font-size: 70%;
}

sup {
    vertical-align: super;
    line-height: 0;
    font-size: 70%;
}

// Text alignment
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-justify {
    text-align: justify;
}

.align-right {
    text-align: right;
}

.align-top {
    vertical-align: top;
}

.align-middle {
    vertical-align: middle;
}

.align-bottom {
    vertical-align: bottom;
}

// text-transform
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.transform-uppercase {
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
}

.transform-lowercase {
    text-transform: lowecase;
}

.transform-none {
    text-transform: none;
}

.transform-small-caps {
    font-variant: small-caps;
}

// Indention
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.indent {
    margin-left: 2em;
}

blockquote {
    border-left: .25em solid #dfe2e5;
    color: #6a737d;
    padding: 0 1em;
}

// underline
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

u,
.underline {
    text-decoration-line: underline;
    text-decoration-style: wavy;
}

// line through
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

s,
.line-through {
    text-decoration: line-through;
}

// ellipsis
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// nowrap
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.nowrap {
    white-space: nowrap;
}

/* tables
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

table.ce-table {
    border-spacing: 0; /* no space between cells */
    border-collapse: separate; /* no collisions between cell-borders */
    *border-collapse: collapse; /* hack is needed for IE7 to prevent space between cells */
    border: none;
    width: 100%;

    td,
    th {
        padding: 0.5rem;
        border-top: 1px solid $default-font-color;
        text-align: left;
        vertical-align: top;
    }

    tr:first-child {
        td,
        th {
            border-top: none;
        }
    }

    th {
        font-weight: $font-weight-bold;
        width: 1%;
        white-space: nowrap;
    }

    td {
        font-weight: $font-weight-bold;
    }

    tr *:first-child {
        padding-left: 0;
    }

    tr *:last-child {
        padding-right: 0;
    }
}

table.ce-table-aside {
    th {
        font-weight: $font-weight-bold;
    }

    td {
        font-weight: $font-weight-regular;
    }

    //a {
    //    border-bottom: 1px dotted $default-font-color;
    //
    //    #{$no-touch-selector} &:hover {
    //        border-bottom-color: $color-yellow;
    //    }
    //}
}

table.ce-markdown {
    border-collapse: collapse;
    border-spacing: 0;

    display: block;
    overflow: auto;
    width: 100%;

    tr {
        border-top: 1px solid #c6cbd1;
        background-color: #fff;
    }

    tbody tr {
        &:nth-child(odd) {
            background-color: #f6f8fa;
        }

        &:nth-child(even) {
            background-color: #fff;
        }
    }

    td,
    th {
        border: 1px solid #dfe2e5;
        padding: 6px 13px;
    }

    th {
        font-weight: $font-weight-bold;
    }
}
