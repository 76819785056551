//.mainsection {
//    height: 100vh;
//}

.navititle {
    position: absolute;
    left: -9999px;
}

//@media screen and (max-width: 768px) {
//    .mainsection {
//        height: initial;
//    }
//}