/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fonts-directory}lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
       url('#{$fonts-directory}lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fonts-directory}lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
       url('#{$fonts-directory}lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fonts-directory}pt-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('PT Sans'), local('PTSans-Regular'),
       url('#{$fonts-directory}pt-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}pt-sans-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}pt-sans-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}pt-sans-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}pt-sans-v11-latin-regular.svg#PTSans') format('svg'); /* Legacy iOS */
}

/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fonts-directory}pt-sans-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local('PT Sans Bold'), local('PTSans-Bold'),
       url('#{$fonts-directory}pt-sans-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}pt-sans-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}pt-sans-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}pt-sans-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}pt-sans-v11-latin-700.svg#PTSans') format('svg'); /* Legacy iOS */
}
