* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// Responsive Images
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

img {
    width: 100%;
    height: auto;
    max-width: 100%;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    border: none;
}

// only for firefox
@-moz-document url-prefix() {
    .rte img {
        width: 100%;
    }
}

// Scrollbar
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

::-webkit-scrollbar {
    display: none;
}

// Position and Display
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.position-relative {
    position: relative;
}

.table {
    display: table;
}

.table-block {
    display: table;
    width: 100%;
}

.table-cell {
    display: table-cell;
}

// Selection
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

::-moz-selection,
::selection {
    background: rgba(0, 0, 0, 0.5);
    color: white;
}

nav ul,
nav li {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
}

// Clear
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.clearfix {
    clear: both;
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
}

// Border around images
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
.ce-border figure {
    display: block;
    padding: 0.5rem;
    border: 1px solid black;
}

// Lightbox Zoom-Cursor on images
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

// zoom-cursor for lightbox-images (modern browser)
a.lightbox-gallery img {
    cursor: -webkit-zoom-in; // Safari/Chrome
    cursor: -moz-zoom-in; // Mozilla
    cursor: -o-zoom-in; // Opera v11+
    cursor: zoom-in; // w3c-draft in css3
}

// zoom-cursor for lightbox-images (only explorer sees this; the w3c-way)
//* a.lightbox-gallery img {
//    cursor: url("#{$path_images}Icons/zoomin.cur"), pointer;
//}

// icon to enlarge images
$size: 3rem;
.lightbox-zoom {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: $size / -2;
    margin-left: $size / -2;
    width: $size;
    height: $size;
    background-color: rgba(0, 0, 0, 0.5);
    @include rounded(50%);
    text-align: center;
    opacity: 0.8;

    i {
        color: white;
        width: $size;
        height: $size;
        font-size: $size * 0.5;
        line-height: $size;
        opacity: 0.7;
    }
}

a.lightbox-gallery {
    position: relative;
}

html.no-touchevents a.lightbox-gallery {
    display: block;
    background-color: black;
    overflow: hidden;

    img {
        @include transition(all 0.3s ease-in-out);
        position: relative;
    }

    .lightbox-zoom {
        @include transition(all 0.2s ease-in-out);
        @include transform(scale(0.5));
        opacity: 0;
    }

    &:hover img {
        @include transform(scale(1.01));
        opacity: 0.8;
    }

    &:hover .lightbox-zoom {
        display: block;
        @include transform(scale(1.02));
        opacity: 0.8;
    }
}

// Copyright/figcaption in ce-images
figure.image {
    position: relative;

    .image-copyright {
        position: absolute;
        bottom: 1vw;
        right: 1vw;
        background-color: rgba(0, 0, 0, 0.25);
        color: white;
        font-size: 12px;
        line-height: 1.4;
        padding: 3px 6px;
        border-radius: 3px;
    }

    figcaption {
        font-size: 80%;
        line-height: 1.2;
        margin-top: 0.5rem;
    }
}

// Responsive Video
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.responsive-video {
    position: relative;
    height: 0;
    padding-bottom: 100% / 16 * 9;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

// Responsive Table
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.responsive-table {
    width: 100%;
    overflow-x: auto;
    margin-left: auto;
    margin-right: auto;

    table::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 14px;
        height: 14px;
    }

    table::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 3px solid #fff;
        background-color: rgba(0, 0, 0, 0.3);
    }

    tr {

        td {
            height: 40px;
            vertical-align: top;

            @include mediaQuery($device_smartphone) {
                height: 30px;
            }
        }

        td:first-child {
            width: 50px;
        }

        &:last-child {
            .red-square-subheadline {
                letter-spacing: 0;
                padding: 1px 2px;
            }
        }
    }

    //th {
    //    font-size: 24px;
    //    letter-spacing: $letter-spacing;
    //}
//
    //td {
    //    font-size: 24px;
    //}
}

// Extbase Messages
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.typo3-messages {
    max-width: 1280px;
    margin: 1em auto !important;
    padding: 0 !important;
    list-style: none;

    .alert-danger,
    .alert-2 {
        border: 1px solid #e43018;
        background-color: #fceae7;
        color: #e43018;
        padding: 1em;
    }

    .alert-warning {
        border: 1px solid #FFA500;
        background-color: #fceae7;
        color: #FFA500;
        padding: 1em;
    }

    .alert-success,
    .alert-0 {
        border: 1px solid #009640;
        background-color: #e5f4ec;
        color: #009640;
        padding: 1em;
    }

    li.alert {
        margin-top: 1em;

        &:first-child {
            margin-top: 0;

            h4 {
                margin-bottom: 0;
            }
        }
    }

    .media-body {
        display: inline-block;
    }

    .media-left {
        display: inline-block;

        .fa-stack-1x {
            color: white;
        }
    }
}

a {
    font-family: $font-family;
    font-weight: $font-weight-light;
    cursor: pointer;
    text-decoration: none;
}

.header-overlay-active {
    .whitediv {
        width: 100%;
        height: 99999px;
        background-color: white;
        position:absolute;
        top:0;
    }
}

.red-hr {
    border-top: 2px solid $color-highlight;
    width: 150px;
    margin-bottom: 0.7rem;
}

.white-hr {
    border-top: 2px solid white;
    width: 150px;
    margin-bottom: 0.7rem;
}

.only-smartphone {
    display: none;

    @include mediaQuery($device_smartphone) {
        display: initial;
    }
}

.only-smartphone-block {
    display: none;

    @include mediaQuery($device_smartphone) {
        display: block;
    }
}

.not-smartphone {
    @include mediaQuery($device_smartphone) {
        display: none;
    }
}


html.page-layout-5 {
    background-color: black;

    .page-centered {
        max-width: 1280px;
    }
}

.lightbox-white-background {
    background-color: black !important;
}

html.page-uid-27, html.page-uid-28 {
    background-color: #000;
    color: #fff;

    a {
        color: #fff;
    }
}

html.lightbox-white-active .usercentrics-button {
    display: none;
}



