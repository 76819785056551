.openingtimes {
    nav.meta {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        bottom: initial;
        padding: 0;
        display: inline-block;
        right: 0;
        
        ul, li, a {
            display: inline-block;
        }
    
        li {

            margin-right: 20px;
        
            a {
                font-family: $font-family-ptsans;
                text-transform: uppercase;
                text-decoration: none;
                color: #fff;
                font-size: 14px;
        
                #{$no-touch-selector} &:hover,
                &.active {
                    color: rgba(255,255,255,0.7);
                }
            }
        }
    }
}

@media screen and (max-width: 480px){
    .openingtimes{

        nav.meta{

            li{ 
                margin-right: 10px;
                a{
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 330px){
    .openingtimes{

        nav.meta{

            li{ 
                margin-right: 8px;
                a{
                    font-size: 9px;
                }
            }
        }
    }
}







.openingtimes {
    nav.meta_contact {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        bottom: initial;
        padding: 0;
        display: inline-block;
        right: 205px;

        .language-1 & {
            right: 210px;
        }
        
        ul, li, a {
            display: inline-block;
        }
    
        li {

            margin-right: 20px;
        
            a {
                font-family: $font-family-ptsans;
                text-transform: uppercase;
                text-decoration: none;
                color: #fff;
                font-size: 14px;
        
                #{$no-touch-selector} &:hover,
                &.active {
                    color: rgba(255,255,255,0.7);
                }
            }
        }
    }
}

@media screen and (max-width: 480px){
    .openingtimes{

        nav.meta_contact{
            right: 165px;

            .language-1 & {
                right: 170px;
            }

            li{ 
                margin-right: 10px;
                a{
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 330px){
    .openingtimes{

        nav.meta_contact{
            right: 125px;

            .language-1 & {
                right: 130px;
            }

            li{ 
                margin-right: 8px;
                a{
                    font-size: 9px;
                }
            }
        }
    }
}