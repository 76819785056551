//.page-centered-wide {
//    margin-left: auto;
//    margin-right: auto;
//    max-width: 1600 / $root-pixel + rem;
//}

.page-centered {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    //background-color: rgba(red, 0.1);

    @include mediaQuery($device_smaller_than_laptop) {
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
}

//html.page-layout-2 {
//    .page-centered {
//        max-width: 800px !important;
//    }
//}

@mixin page-space ($prefix: "", $margin-left: auto, $margin-right: auto) {
    .#{$prefix}display-none {             display: none; }
    .#{$prefix}display-block {            display: block; }
    .#{$prefix}display-inline-block {     display: inline-block; }

    .#{$prefix}no-space-left {     padding-left:   0; margin-left:   0; }
    .#{$prefix}no-space-top {      padding-top:    0; margin-top:    0; }
    .#{$prefix}no-space-bottom {   padding-bottom: 0; margin-bottom: 0; }
    .#{$prefix}no-space-right {    padding-right:  0; margin-right:  0; }

    .#{$prefix}space-left-8 {      padding-left:   $size-8; }
    .#{$prefix}space-top-8 {       padding-top:    $size-8; }
    .#{$prefix}space-bottom-8 {    padding-bottom: $size-8; }
    .#{$prefix}space-right-8 {     padding-right:  $size-8; }

    .#{$prefix}space-left-16 {     padding-left:   $size-16; }
    .#{$prefix}space-top-16 {      padding-top:    $size-16; }
    .#{$prefix}space-bottom-16 {   padding-bottom: $size-16; }
    .#{$prefix}space-right-16 {    padding-right:  $size-16; }

    .#{$prefix}space-left-24 {     padding-left:   $size-24; }
    .#{$prefix}space-top-24 {      padding-top:    $size-24; }
    .#{$prefix}space-bottom-24 {   padding-bottom: $size-24; }
    .#{$prefix}space-right-24 {    padding-right:  $size-24; }

    .#{$prefix}space-left-32 {     padding-left:   $size-32; }
    .#{$prefix}space-top-32 {      padding-top:    $size-32; }
    .#{$prefix}space-bottom-32 {   padding-bottom: $size-32; }
    .#{$prefix}space-right-32 {    padding-right:  $size-32; }

    .#{$prefix}space-left-40 {     padding-left:   $size-40; }
    .#{$prefix}space-top-40 {      padding-top:    $size-40; }
    .#{$prefix}space-bottom-40 {   padding-bottom: $size-40; }
    .#{$prefix}space-right-40 {    padding-right:  $size-40; }

    .#{$prefix}space-left-48 {     padding-left:   $size-48; }
    .#{$prefix}space-top-48 {      padding-top:    $size-48; }
    .#{$prefix}space-bottom-48 {   padding-bottom: $size-48; }
    .#{$prefix}space-right-48 {    padding-right:  $size-48; }

    .#{$prefix}space-left-56 {     padding-left:   $size-56; }
    .#{$prefix}space-top-56 {      padding-top:    $size-56; }
    .#{$prefix}space-bottom-56 {   padding-bottom: $size-56; }
    .#{$prefix}space-right-56 {    padding-right:  $size-56; }

    .#{$prefix}space-left-64 {     padding-left:   $size-64; }
    .#{$prefix}space-top-64 {      padding-top:    $size-64; }
    .#{$prefix}space-bottom-64 {   padding-bottom: $size-64; }
    .#{$prefix}space-right-64 {    padding-right:  $size-64; }

    .#{$prefix}space-left-72 {     padding-left:   $size-72; }
    .#{$prefix}space-top-72 {      padding-top:    $size-72; }
    .#{$prefix}space-bottom-72 {   padding-bottom: $size-72; }
    .#{$prefix}space-right-72 {    padding-right:  $size-72; }

    .#{$prefix}space-left-80 {     padding-left:   $size-80; }
    .#{$prefix}space-top-80 {      padding-top:    $size-80; }
    .#{$prefix}space-bottom-80 {   padding-bottom: $size-80; }
    .#{$prefix}space-right-80 {    padding-right:  $size-80; }

    .#{$prefix}space-left-88 {     padding-left:   $size-88; }
    .#{$prefix}space-top-88 {      padding-top:    $size-88; }
    .#{$prefix}space-bottom-88 {   padding-bottom: $size-88; }
    .#{$prefix}space-right-88 {    padding-right:  $size-88; }

    .#{$prefix}space-left-96 {     padding-left:   $size-96; }
    .#{$prefix}space-top-96 {      padding-top:    $size-96; }
    .#{$prefix}space-bottom-96 {   padding-bottom: $size-96; }
    .#{$prefix}space-right-96 {    padding-right:  $size-96; }

    .#{$prefix}space-left-104 {    padding-left:   $size-104; }
    .#{$prefix}space-top-104 {     padding-top:    $size-104; }
    .#{$prefix}space-bottom-104 {  padding-bottom: $size-104; }
    .#{$prefix}space-right-104 {   padding-right:  $size-104; }

    .#{$prefix}space-left-112 {    padding-left:   $size-112; }
    .#{$prefix}space-top-112 {     padding-top:    $size-112; }
    .#{$prefix}space-bottom-112 {  padding-bottom: $size-112; }
    .#{$prefix}space-right-112 {   padding-right:  $size-112; }

    .#{$prefix}space-left-120 {    padding-left:   $size-120; }
    .#{$prefix}space-top-120 {     padding-top:    $size-120; }
    .#{$prefix}space-bottom-120 {  padding-bottom: $size-120; }
    .#{$prefix}space-right-120 {   padding-right:  $size-120; }

    .#{$prefix}space-left-128 {    padding-left:   $size-128; }
    .#{$prefix}space-top-128 {     padding-top:    $size-128; }
    .#{$prefix}space-bottom-128 {  padding-bottom: $size-128; }
    .#{$prefix}space-right-128 {   padding-right:  $size-128; }

    .#{$prefix}space-left-136 {    padding-left:   $size-136; }
    .#{$prefix}space-top-136 {     padding-top:    $size-136; }
    .#{$prefix}space-bottom-136 {  padding-bottom: $size-136; }
    .#{$prefix}space-right-136 {   padding-right:  $size-136; }

    .#{$prefix}space-left-144 {    padding-left:   $size-144; }
    .#{$prefix}space-top-144 {     padding-top:    $size-144; }
    .#{$prefix}space-bottom-144 {  padding-bottom: $size-144; }
    .#{$prefix}space-right-144 {   padding-right:  $size-144; }

    .#{$prefix}space-left-152 {    padding-left:   $size-152; }
    .#{$prefix}space-top-152 {     padding-top:    $size-152; }
    .#{$prefix}space-bottom-152 {  padding-bottom: $size-152; }
    .#{$prefix}space-right-152 {   padding-right:  $size-152; }

    .#{$prefix}space-left-160 {    padding-left:   $size-160; }
    .#{$prefix}space-top-160 {     padding-top:    $size-160; }
    .#{$prefix}space-bottom-160 {  padding-bottom: $size-160; }
    .#{$prefix}space-right-160 {   padding-right:  $size-160; }

    .#{$prefix}outer-space-left--16,
    .#{$prefix}space-left--16 {          margin-left:   $size--16; }
    .#{$prefix}outer-space-top--16,
    .#{$prefix}space-top--16 {           margin-top:    $size--16; }
    .#{$prefix}outer-space-bottom--16,
    .#{$prefix}space-bottom--16 {        margin-bottom: $size--16; }
    .#{$prefix}outer-space-right--16,
    .#{$prefix}space-right--16 {         margin-right:  $size--16; }

    .#{$prefix}outer-space-left--12,
    .#{$prefix}space-left--12 {          margin-left:   $size--12; }
    .#{$prefix}outer-space-top--12,
    .#{$prefix}space-top--12 {           margin-top:    $size--12; }
    .#{$prefix}outer-space-bottom--12,
    .#{$prefix}space-bottom--12 {        margin-bottom: $size--12; }
    .#{$prefix}outer-space-right--12,
    .#{$prefix}space-right--12 {         margin-right:  $size--12; }

    .#{$prefix}outer-space-left--8,
    .#{$prefix}space-left--8 {           margin-left:   $size--8; }
    .#{$prefix}outer-space-top--8,
    .#{$prefix}space-top--8 {            margin-top:    $size--8; }
    .#{$prefix}outer-space-bottom--8,
    .#{$prefix}space-bottom--8 {         margin-bottom: $size--8; }
    .#{$prefix}outer-space-right--8,
    .#{$prefix}space-right--8 {          margin-right:  $size--8; }

    .#{$prefix}outer-space-left--4,
    .#{$prefix}space-left--4 {           margin-left:   $size--4; }
    .#{$prefix}outer-space-top--4,
    .#{$prefix}space-top--4 {            margin-top:    $size--4; }
    .#{$prefix}outer-space-bottom--4,
    .#{$prefix}space-bottom--4 {         margin-bottom: $size--4; }
    .#{$prefix}outer-space-right--4,
    .#{$prefix}space-right--4 {          margin-right:  $size--4; }

    .#{$prefix}outer-space-left-8 {      margin-left:   $size-8; }
    .#{$prefix}outer-space-top-8 {       margin-top:    $size-8; }
    .#{$prefix}outer-space-bottom-8 {    margin-bottom: $size-8; }
    .#{$prefix}outer-space-right-8 {     margin-right:  $size-8; }

    .#{$prefix}outer-space-left-16 {     margin-left:   $size-16; }
    .#{$prefix}outer-space-top-16 {      margin-top:    $size-16; }
    .#{$prefix}outer-space-bottom-16 {   margin-bottom: $size-16; }
    .#{$prefix}outer-space-right-16 {    margin-right:  $size-16; }

    .#{$prefix}outer-space-left-24 {     margin-left:   $size-24; }
    .#{$prefix}outer-space-top-24 {      margin-top:    $size-24; }
    .#{$prefix}outer-space-bottom-24 {   margin-bottom: $size-24; }
    .#{$prefix}outer-space-right-24 {    margin-right:  $size-24; }

    .#{$prefix}outer-space-left-32 {     margin-left:   $size-32; }
    .#{$prefix}outer-space-top-32 {      margin-top:    $size-32; }
    .#{$prefix}outer-space-bottom-32 {   margin-bottom: $size-32; }
    .#{$prefix}outer-space-right-32 {    margin-right:  $size-32; }

    .#{$prefix}outer-space-left-40 {     margin-left:   $size-40; }
    .#{$prefix}outer-space-top-40 {      margin-top:    $size-40; }
    .#{$prefix}outer-space-bottom-40 {   margin-bottom: $size-40; }
    .#{$prefix}outer-space-right-40 {    margin-right:  $size-40; }

    .#{$prefix}outer-space-left-48 {     margin-left:   $size-48; }
    .#{$prefix}outer-space-top-48 {      margin-top:    $size-48; }
    .#{$prefix}outer-space-bottom-48 {   margin-bottom: $size-48; }
    .#{$prefix}outer-space-right-48 {    margin-right:  $size-48; }

    .#{$prefix}outer-space-left-56 {     margin-left:   $size-56; }
    .#{$prefix}outer-space-top-56 {      margin-top:    $size-56; }
    .#{$prefix}outer-space-bottom-56 {   margin-bottom: $size-56; }
    .#{$prefix}outer-space-right-56 {    margin-right:  $size-56; }

    .#{$prefix}outer-space-left-64 {     margin-left:   $size-64; }
    .#{$prefix}outer-space-top-64 {      margin-top:    $size-64; }
    .#{$prefix}outer-space-bottom-64 {   margin-bottom: $size-64; }
    .#{$prefix}outer-space-right-64 {    margin-right:  $size-64; }

    .#{$prefix}outer-space-left-72 {     margin-left:   $size-72; }
    .#{$prefix}outer-space-top-72 {      margin-top:    $size-72; }
    .#{$prefix}outer-space-bottom-72 {   margin-bottom: $size-72; }
    .#{$prefix}outer-space-right-72 {    margin-right:  $size-72; }

    .#{$prefix}outer-space-left-80 {     margin-left:   $size-80; }
    .#{$prefix}outer-space-top-80 {      margin-top:    $size-80; }
    .#{$prefix}outer-space-bottom-80 {   margin-bottom: $size-80; }
    .#{$prefix}outer-space-right-80 {    margin-right:  $size-80; }

    .#{$prefix}outer-space-left-88 {     margin-left:   $size-88; }
    .#{$prefix}outer-space-top-88 {      margin-top:    $size-88; }
    .#{$prefix}outer-space-bottom-88 {   margin-bottom: $size-88; }
    .#{$prefix}outer-space-right-88 {    margin-right:  $size-88; }

    .#{$prefix}outer-space-left-96 {     margin-left:   $size-96; }
    .#{$prefix}outer-space-top-96 {      margin-top:    $size-96; }
    .#{$prefix}outer-space-bottom-96 {   margin-bottom: $size-96; }
    .#{$prefix}outer-space-right-96 {    margin-right:  $size-96; }

    .#{$prefix}outer-space-left-104 {    margin-left:   $size-104; }
    .#{$prefix}outer-space-top-104 {     margin-top:    $size-104; }
    .#{$prefix}outer-space-bottom-104 {  margin-bottom: $size-104; }
    .#{$prefix}outer-space-right-104 {   margin-right:  $size-104; }

    .#{$prefix}outer-space-left-112 {    margin-left:   $size-112; }
    .#{$prefix}outer-space-top-112 {     margin-top:    $size-112; }
    .#{$prefix}outer-space-bottom-112 {  margin-bottom: $size-112; }
    .#{$prefix}outer-space-right-112 {   margin-right:  $size-112; }

    .#{$prefix}outer-space-left-120 {    margin-left:   $size-120; }
    .#{$prefix}outer-space-top-120 {     margin-top:    $size-120; }
    .#{$prefix}outer-space-bottom-120 {  margin-bottom: $size-120; }
    .#{$prefix}outer-space-right-120 {   margin-right:  $size-120; }

    .#{$prefix}outer-space-left-128 {    margin-left:   $size-128; }
    .#{$prefix}outer-space-top-128 {     margin-top:    $size-128; }
    .#{$prefix}outer-space-bottom-128 {  margin-bottom: $size-128; }
    .#{$prefix}outer-space-right-128 {   margin-right:  $size-128; }

    .#{$prefix}outer-space-left-136 {    margin-left:   $size-136; }
    .#{$prefix}outer-space-top-136 {     margin-top:    $size-136; }
    .#{$prefix}outer-space-bottom-136 {  margin-bottom: $size-136; }
    .#{$prefix}outer-space-right-136 {   margin-right:  $size-136; }

    .#{$prefix}outer-space-left-144 {    margin-left:   $size-144; }
    .#{$prefix}outer-space-top-144 {     margin-top:    $size-144; }
    .#{$prefix}outer-space-bottom-144 {  margin-bottom: $size-144; }
    .#{$prefix}outer-space-right-144 {   margin-right:  $size-144; }

    .#{$prefix}outer-space-left-152 {    margin-left:   $size-152; }
    .#{$prefix}outer-space-top-152 {     margin-top:    $size-152; }
    .#{$prefix}outer-space-bottom-152 {  margin-bottom: $size-152; }
    .#{$prefix}outer-space-right-152 {   margin-right:  $size-152; }

    .#{$prefix}outer-space-left-160 {    margin-left:   $size-160; }
    .#{$prefix}outer-space-top-160 {     margin-top:    $size-160; }
    .#{$prefix}outer-space-bottom-160 {  margin-bottom: $size-160; }
    .#{$prefix}outer-space-right-160 {   margin-right:  $size-160; }
}

// Default styles
// page-centered max-width, device-prefix, page-centered margin-left, page-centered margin-right
@include page-space ("", auto, auto);

// Styles for each breakpoint
@include mediaQuery($device_desktop) {
    @include page-space ("desktop--", auto, auto);
}
@include mediaQuery($device_laptop) {
    @include page-space ("laptop--", 2vw, 2vw);
}
@include mediaQuery($device_tablet) {
    @include page-space ("tablet--", 2vw, 2vw);
}
@include mediaQuery($device_smartphone) {
    @include page-space ("smartphone--", 2vw, 2vw);
}
