#footer {


    margin-bottom: 3rem;

    .yellow-dot {
        width: 10px;
        margin: 0 6px 4px;
    }

    .yellow-line {
        border-top: 2px solid $color-highlight;
        width: 200px;
        margin: -1rem auto 0;
    }

    //.content {
    //    
    //}

    // Logo + Address + Contact
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    .sections {
        display: flex;
        justify-content: space-between;
        align-items: start;

        & > a,
        & > div {
            display: inline-block;
        }

        a {
            text-decoration: none;
        }
    }

    // Logo
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =


    &__logo {
        display: block;
        width: 350px;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 2rem;
    }

    // Contact
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    .address {
        width: 100%;
        display: block;
        vertical-align: top;
        margin-left: 50%;
        transform: translateX(-50%);
        text-align: center;

        a {
            font-family: $font-family;
            font-weight: $font-weight-regular;
            text-decoration: none;
        }
    }

    // Navigation
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    nav.info {
        ul,
        li {
            display: inline-block;
        }

        li {
            margin-left: 2em;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                display: none;
            }
        }

        a {
            text-decoration: none;
            font-size: 80%;

            &.active {
                color: $color-highlight;
            }
        }
    }
}




@include mediaQuery($device_tablet) {
    // alignment of section items on tablet
    #footer {
        .sections {
            flex-wrap: wrap;
            //margin-left: -2rem;
            align-items: flex-end;
        }

        a.logo {
            width: 51%;
            //padding-left: 2rem;
        }

        .address,
        .openingtimes-footer {
            width: 50%;
            //padding-left: 2rem;
            margin-top: 2rem;
        }
        .address {
            padding-right: 1rem;
        }
        .openingtimes-footer {
            padding-left: 1rem;
        }
    }
}

@include mediaQuery($device_smartphone) {
    // alignment of section items on smartphone
    #footer {
        .sections {
            display: block;
        }


        &__logo {
            display: block;
            width: 75%;

            img {
                width: 100%
            }
        }
        .address {
            padding-right: initial;
        }

        .address,
        .openingtimes-footer {
            display: block;
            width: 100%;
            margin-top: 2rem;
        }
    }
}